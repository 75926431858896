import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";
import { objectToQuery } from "../../ObjectToQuery";


class DepositUser  {
    id: string;
    name: string;
    id_card: string;
    mobile: string;
    phone: string;
    email: string;
    username: string;
    sex: string;
}

export class GetDepositsResponse {
    user: DepositUser;
    id: string;
    type: string;
    value: number;
    datePaid: Date;
    dateCreated: Date;
}

class Params {
    per_page?: number = 20;
    page?: number = 0;
    paid?: 0|1;
}

export function getDeposits(params: Params): Promise<AxiosResponse<{count: number, deposits: GetDepositsResponse[]}>> {
    const query = `/admin/deposits?${objectToQuery(params)}`;
    return Cirus.get(query);
}