export function objectToQuery (params: any) {    
    if (!params) return '';
    if (Object.keys(params).length === 0 && params.constructor === Object) return '';
    return encodeURI(Object.keys(params).map((key: string): string => {
        let result = '';
        const value = params[key];
        if (Array.isArray(value)) {
            result = value.map((param: string, i: number) => {
                return `${key}[${i}]=${param}`
            }).join('&')
        } else if (typeof value !== 'undefined' && value!==null) result = key + '=' + value
        return result;
    }).join('&'));
}