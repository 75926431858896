import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";
import { objectToQuery } from "../../ObjectToQuery";


class WithdrawalUser  {
    id: string;
    name: string;
    id_card: string;
    mobile: string;
    phone: string;
    email: string;
    username: string;
    sex: string;
}

class withdrawalAccount {
    IBAN: string;
    id: string;
    owner_name: string;
    user_id: string;
}

export class GetWithdrawsResponse {
    user: WithdrawalUser;
    account: withdrawalAccount;
    id: string;
    type: string;
    value: number;
    datePaid: Date;
    dateCreated: Date;
}

class Params {
    per_page?: number = 20;
    page?: number = 0;
    paid?: 0|1;
}

export function getWithdrawals(params: Params): Promise<AxiosResponse<{withdrawals: GetWithdrawsResponse[], count: number}>> {
    const query = `/admin/withdrawals?${objectToQuery(params)}`;
    return Cirus.get(query);
}