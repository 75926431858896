










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Pagination extends Vue {
    @Prop({default: 0}) page!: number;
    @Prop({default: 0}) count: number;
    @Prop({default: 20}) perPage: number;

    
    public get pageCount() : number {
        return Math.ceil(this.count/this.perPage);        
    }
    

    nextPage () {
        let page = this.page;
        if (page>= this.pageCount-1) page = this.pageCount;
        else page++;
        this.page = page;
        this.$emit('change', page)
    }

    previusPage () {
        let page = this.page;
        if (page<=0) page = 0;
        else page--;
        this.page = page;
        this.$emit('change', page)
    }

}
