
































import Pagination from '@/components/Pagination.vue';
import { Vue, Component } from 'vue-property-decorator';
import CopyToClipboard from 'vue-copy-to-clipboard'
import { getDeposits, GetDepositsResponse, getWithdrawals, GetWithdrawsResponse } from '@/classes/TayehApi/Credit';
@Component({
    components: {
        Pagination,
        CopyToClipboard
    }
})
export default class Users extends Vue {
    page = 0;
    count = 0;
    search: string = null;
    deposits: GetDepositsResponse[] = [];
    paid_deposits = true;
    pageChange (page: number) {
        this.page = page;
        this.getDeposits();
    }

    async getDeposits () {
        const res = await getDeposits({paid: this.paid_deposits?1:0});
        this.count = res.data.count;
        this.deposits = res.data.deposits;
        // this.data = res.data;
    }

    mounted() {
        this.getDeposits()
    }
}
